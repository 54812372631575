<template>
	<div class="page">
		<div class="toolbar" ref="toolbar">
			<div class="tit"> 编辑设备 </div>
			<div class="action-box">
				<el-button @click="save('form')" size="small" icon="el-icon-check" plain v-if="$hasAccess('device.device/edit')">保存 </el-button>
				<el-button @click="close" size="small" icon="el-icon-close" plain>取消 </el-button>
			</div>
		</div>
		<el-card class="box-card card">
			<el-form :model="formData" :rules="rules" ref="form" label-width="120px">
				<el-row>
					<!-- 主栏 -->
					<el-col :xs="24" :sm="10">
						<!-- 设备信息 -->
						<div class="el-block">
							<div class="row-title">设备信息</div>
							<!-- 设备编号 -->
							<el-form-item label="设备编号" prop="device_no">
								<el-input v-model.trim="formData.device_no" clearable />
							</el-form-item>
							<!-- 通讯编号 -->
							<el-form-item label="通讯编号" prop="device_num">
								<el-input v-model.trim="formData.device_num" clearable />
							</el-form-item>
							<!-- 设备类型 -->
							<el-form-item label="设备类型" prop="type_id">
								<el-select v-model="formData.type_id" clearable="" placeholder="请选择设备类型" style="width:100%">
									<el-option v-for="type in typeList" :key="type.type_id" :label="type.type_name" :value="type.type_id">
									</el-option>
								</el-select>
							</el-form-item>
							<!-- 设备类型 -->
							<el-form-item label="枪口类型" prop="qk_type">
								<el-select v-model="formData.device_type" clearable="" placeholder="请选择枪口类型" style="width:100%">
									<el-option v-for="type in device_type" :key="type.type_id" :label="type.type_name" :value="type.type_id">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="车位号" prop="parking_number">
								<el-input v-model.trim="formData.parking_number" clearable="" />
							</el-form-item>
							<!-- 设备状态 -->
							<el-form-item label="设备状态" prop="device_status">
								<el-radio-group v-model="formData.device_status">
									<el-radio :label="20">正常</el-radio>
									<el-radio :label="30">离线</el-radio>
									<!-- <el-radio :label="40">充电中</el-radio> -->
								</el-radio-group>
							</el-form-item>
							<!-- 网络类型 -->
							<el-form-item label="网络类型" prop="network">
								<el-radio-group v-model="formData.network">
									<el-radio :label="0">未知</el-radio>
									<el-radio :label="1">2G</el-radio>
									<el-radio :label="2">4G</el-radio>
									<!-- <el-radio :label="40">充电中</el-radio> -->
								</el-radio-group>
							</el-form-item>
              <!-- 绑定账户 -->
              <el-form-item label="绑定账户" v-if="formData.group_id == 19" prop="type_id">
                  <el-input v-model.trim="formData.user_id" clearable />
              </el-form-item>
							<el-form-item label="小区" prop="group_id">
								<!--								<el-select v-model="formData.group_id" placeholder="请选择小区" style="width:100%">-->
								<!--									<el-option v-for="group in groupList" :key="group.group_id" :label="group.group_name" :value="group.group_id">-->
								<!--									</el-option>-->
								<!--								</el-select>-->
								<el-select v-model="formData.group_id" size="small" style="width: 100%" filterable remote reserve-keyword clearable placeholder="请选择小区" :remote-method="remoteGroup"
									:loading="groupLoading">
									<el-option v-for="item in groupList" :key="item.group_id" :label="item.group_name" :value="item.group_id">
									</el-option>
								</el-select>
							</el-form-item>
							<div class="el-row--flex">
								<el-form-item label="最大充电功率" prop="max_power">
									<el-input v-model="formData.max_power" clearable="" type="number" />
								</el-form-item>
							</div>
<!--							<el-form-item label="选择地址" prop="city">-->
<!--								<div>-->
<!--									<el-button type="text" circle icon="el-icon-location-outline" @click="openMap">选择</el-button>-->
<!--									<span>{{formData.province}}-{{formData.city}}-{{formData.county}}</span>-->
<!--								</div>-->
<!--							</el-form-item>-->
<!--							<el-form-item label="详细地址" prop="address">-->
<!--								<el-input v-model="formData.address" clearable />-->
<!--							</el-form-item>-->
<!--							<el-form-item label="备注" prop="remark">-->
<!--								<el-input v-model="formData.remark" type="textarea" clearable />-->
<!--							</el-form-item>-->
						</div>
					</el-col>
					<!-- 副栏 -->
					<el-col :xs="{span:24,offset:0}" :sm="{span:10,offset:2}">
						<!-- 模式信息 -->
						<div class="el-block">
							<div class="row-title">模式信息</div>
<!--							<el-form-item label="钉钉报警" prop="fee_type">-->
<!--								<el-radio-group v-model="formData.alarm_status">-->
<!--									<el-radio :label="10">报警</el-radio>-->
<!--									<el-radio :label="20">屏蔽</el-radio>-->
<!--								</el-radio-group>-->
<!--							</el-form-item>-->
<!--							<el-form-item label="费率" prop="fee_id">-->
<!--								<el-select v-model="formData.fee_id" placeholder="请选择费率" clearable style="width:100%">-->
<!--									<el-option v-for="fee in feeList" :key="fee.fee_id" :label="fee.fee_name" :value="fee.fee_id">-->
<!--									</el-option>-->
<!--								</el-select>-->
<!--							</el-form-item>-->
							<el-form-item label="联网模式" prop="fee_type">
								<el-radio-group v-model="formData.fee_type">
									<el-radio :label="10">互联互通</el-radio>
									<el-radio :label="20">直连</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="枪口模式" prop="fee_model">
								<el-radio-group v-model="formData.fee_model">
									<el-radio :label="10">快充</el-radio>
									<el-radio :label="20">慢充</el-radio>
								</el-radio-group>
							</el-form-item>
<!--							<el-form-item label="允许临时充电" prop="allow_short">-->
<!--								<el-radio-group v-model="formData.allow_short">-->
<!--									<el-radio :label="10">允许</el-radio>-->
<!--									<el-radio :label="20">不允许</el-radio>-->
<!--								</el-radio-group>-->
<!--							</el-form-item>-->
						</div>
						<!-- 代理信息 -->
						<div class="el-block">
							<div class="row-title">代理信息</div>
							<div class="agent-choose-form">
								<div class="title">
									<span class="tips">代理商选择</span>
									<el-button class="add-button" icon="el-icon-plus" type="primary" size="small" @click="onAgentItemAdd">增加代理</el-button>
								</div>
								<div v-for="idx in cooperateAgentList.length" class="agent-item">
									<el-select v-model="cooperateAgentList[idx-1].agent_id" class="select" size="small" placeholder="请选择代理商" clearable>
										<el-option v-for="item in agentList" :key="item.agent_id" :label="item.agent_name" :value="item.agent_id">
										</el-option>
									</el-select>
									<el-input-number type="number" :min="0" :max="1" :step="0.1" :precision="2" v-model="cooperateAgentList[idx-1].ratio" class="input" size="small"
										placeholder="请输入分成比例"></el-input-number>
									<el-button class="del-button" size="small" type="danger" icon="el-icon-delete" @click="onAgentItemDel(idx)">删除</el-button>
								</div>
								<!-- 								<div class="agent-choose-error-tips">
									<span v-show="cooperateAgentErrTips">* 代理商分成比例累计需要为100%</span>
								</div> -->
							</div>
							<el-form-item label="合作模式" prop="cooperate_model">
								<el-radio-group v-model="formData.cooperate_model">
									<el-radio :label="10">买电</el-radio>
									<el-radio :label="20">分成-充值充电</el-radio>
									<el-radio :label="30">分成-充电</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="合作规则" prop="cooperate_rule">
								<el-row>
									<el-col :span="11" v-if="formData.cooperate_model == 10">
										<el-tooltip class="item" effect="dark" content="买电" placement="bottom-end">
											<el-input-number v-model="cooperate_rule.buy" controls-position="right" :min="0" :max="100" :step="0.1" :precision="2" placeholder="买电" style="width:100%">
											</el-input-number>
										</el-tooltip>
									</el-col>
									<el-col :span="11" v-if="formData.cooperate_model == 20">
										<el-tooltip class="item" effect="dark" content="充值分成" placement="bottom-end">
											<el-input-number v-model="cooperate_rule.recharge" controls-position="right" :min="0" :max="1" :step="0.1" :precision="2" placeholder="充值分成"
												style="width:100%"></el-input-number>
										</el-tooltip>
									</el-col>
									<el-col :span="11" v-if="formData.cooperate_model == 30">
										<el-tooltip class="item" effect="dark" content="充电分成" placement="bottom-end">
											<el-input-number v-model="cooperate_rule.order" controls-position="right" :min="0" :max="1" :step="0.1" :precision="2" placeholder="充电分成"
												style="width:100%"></el-input-number>
										</el-tooltip>
									</el-col>
								</el-row>
							</el-form-item>
							<el-form-item label="底层平台" prop="hlht">
								<el-select v-model="formData.hlht" clearable="" placeholder="请选择底层平台" style="width:100%">
									<el-option v-for="type in hlht" :key="type.type_id" :label="type.type_name" :value="type.type_id">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="底层协议" prop="agree">
								<el-select v-model="formData.agree" clearable="" placeholder="请选择底层协议" style="width:100%">
									<el-option v-for="type in agree" :key="type.type_id" :label="type.type_name" :value="type.type_id">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
						<!-- 优惠信息 -->
<!--						<div class="el-block">-->
<!--							<div class="row-title">优惠信息</div>-->
<!--							<el-form-item label="充值优惠" prop="plan_id">-->
<!--								<el-select v-model="plan_id" placeholder="请选择充值优惠" multiple clearable style="width:100%">-->
<!--									<el-option v-for="item in planList" :key="item.plan_id" :label="item.plan_name" :value="item.plan_id">-->
<!--									</el-option>-->
<!--								</el-select>-->
<!--							</el-form-item>-->
<!--							<el-form-item label="充电优惠" prop="gift_id">-->
<!--								<el-select v-model="formData.gift_id" placeholder="请选择充电优惠" clearable style="width:100%">-->
<!--									<el-option v-for="item in giftList" :key="item.gift_id" :label="item.gift_name" :value="item.gift_id">-->
<!--									</el-option>-->
<!--								</el-select>-->
<!--							</el-form-item>-->
<!--						</div>-->
					</el-col>
				</el-row>
			</el-form>
		</el-card>
		<div v-if="showMap">
			<BMap @mapChooseComplete="handleMapComplete" @mapClose="handleMapClose"></BMap>
		</div>
	</div>
</template>
<script>
	import {
		mapMutations
	} from 'vuex'
	import BMap from '@/components/b-map'
	import {
		bd2gd,
		gd2bd,
	} from '../../../utils/util'
	export default {
		name: 'device-device-edit',
		components: {
			BMap
		},
		data() {
			return {
				device_id: '',
				groupLoading: false,
				// 表单信息
				formData: {},
				// 代理商分成数组
				cooperateAgentList: [{
					agent_id: null,
					ratio: null
				}],
				// 表单规则
				rules: {
					device_no: [{
						required: true,
						whitespace: true,
						message: '请输入设备编号',
						trigger: 'change'
					}],
					device_num: [{
						required: true,
						whitespace: true,
						message: '请输入通讯编号',
						trigger: 'change'
					},{
						pattern: /^[0-9]+$/,
						message: '通讯编号纯数字',
						trigger: 'change'
					}]
				},
				// 特殊字段-合作规则
				cooperate_rule: {
					order: 0,
					recharge: 0
				},
				// 特殊字段-充值优惠
				plan_id: "",
				plug_ids: "",
				// 选项列表
				typeList: [],
				agentList: [],
        userList: [],
				planList: [],
				giftList: [],
				groupList: [],
				unUsedTwoPlugsList: [],
				// 地图
				showMap: false,
				// 设备类型
				device_type: [{
					type_id: 10,
					type_name: '单枪'
				}, {
					type_id: 20,
					type_name: '双枪'
				}],
				// 设备类型
				hlht: [{
					type_id: 0,
					type_name: "星星",
				}, {
					type_id: 1,
					type_name: "云快充",
				}, {
					type_id: 2,
					type_name: "达客云",
				}, {
					type_id: 3,
					type_name: "叮当",
				}, {
					type_id: 4,
					type_name: "元元",
				}],
				// 底层协议
				agree: [{
					type_id: 0,
					type_name: "叮当",
				}, {
					type_id: 1,
					type_name: "恒致达",
				}],
			}
		},
		mounted() {
			this.init()
		},
		async activated() {
			this.init()
		},
		watch: {
			"cooperate_rule.recharge"() {
				this.cooperate_rule.order = this.cooperate_rule.recharge;
			},
			"cooperate_rule.order"() {
				this.cooperate_rule.recharge = this.cooperate_rule.order;
			},
		},
		methods: {
			...mapMutations(['setTabList']),
			// 初始化请求
			init() {
				if (this.$route.query.device_id) {
					if (this.device_id != this.$route.query.device_id) {
						this.device_id = this.$route.query.device_id;
						this.getTypeList();
						this.getAgentList();
						this.getPlanList();
						this.getGiftList();
						this.getGroupList();
						this.getDeviceDetail();
					}
				} else {
					this.$confirm('当前页面数据失效，请返回项目列表重新打开', '提示', {
						cancelButtonClass: 'btn-custom-cancel',
						confirmButtonText: '确定',
						showCancelButton: false,
						showClose: false,
						closeOnClickModal: false,
						type: 'warning'
					}).then(() => {
						this.closeTab()
					})
				}
			},
			// 获取费率信息
			async getTypeList() {
				const params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 100
				}
				const res = await this.$api.Device.GetTypeList(params, {})
				this.typeList = res.data
			},
			// 获取代理商信息
			async getAgentList() {
				const params = {
					token: this.$store.state.user.token,
					status: 40,
					page: 1,
					size: 100
				}
				const ziying = {
					agent_id: 0,
					agent_name: '自营'
				}
				const res = await this.$api.Agent.GetAgentList(params, {})
				res.data.unshift(ziying)
				this.agentList = res.data
			},
			// 获取充值优惠信息
			async getPlanList() {
				const params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 100
				}
				const res = await this.$api.Market.GetPlanList(params, {})
				this.planList = res.data
			},
			// 小区
			async remoteGroup(query) {
				if (query !== '') {
					this.groupLoading = true
					const params = {
						token: this.$store.state.user.token
					}
					const res = await this.$api.Device.GetGroupList(params, {
						group_name: query
					})
					this.groupList = res.data
					this.groupLoading = false
				} else {
					this.groupList = []
				}
			},
			// 获取充电优惠信息
			async getGiftList() {
				const params = {
					token: this.$store.state.user.token,
					status: 10,
					page: 1,
					size: 100
				}
				const res = await this.$api.Market.GetGiftList(params, {})
				this.giftList = res.data
			},
			// 获取小区信息
			async getGroupList() {
				const params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 1000
				}
				const res = await this.$api.Device.GetGroupList(params, {})
				this.groupList = res.data
			},
			// 获取设备信息
			async getDeviceDetail() {
				const params = {
					token: this.$store.state.user.token,
					device_id: this.device_id
				}
				const res = await this.$api.Device.GetDeviceDetail(params);
				if (res.device_type == 50) {
					await this.getUnusedTwoPlugs();
					this.getBoundPlugsList();
				}

				this.formData = res;
        this.cooperateAgentList = res.agent_data ? JSON.parse(res.agent_data) : [{
					agent_id: 0,
					ratio: 1
				}];
				this.cooperate_rule = JSON.parse(res.cooperate_rule);
				const plan_id = res.plan_id.toString().split(',');
				for (let i = 0; i < plan_id.length; i++) {
					plan_id[i] = parseInt(plan_id[i])
				}
				this.plan_id = plan_id
			},
			// 获取未绑定插座信息
			async getUnusedTwoPlugs() {
				const params = {
					token: this.$store.state.user.token,
				}
				const res = await this.$api.Device.UnusedTwoPlugs(params);
				this.unUsedTwoPlugsList = res;
			},
			// 获取已绑定插座的信息

			async getBoundPlugsList() {
				const params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 200,
				};
				const searchData = {
					device_id: this.device_id,
				};
				const res = await this.$api.Device.GetPlugList(params, searchData);
				let unUsed = [];
				let pids = [];
				for (let i = 0; i < res.data.length; ++i) {
					let plug = res.data[i];
					pids.push(plug.plug_id);
					let un = {
						plug_id: plug.plug_id,
						plug_name: plug.plug_name
					}
					unUsed.push(un);
				}
				this.unUsedTwoPlugsList = this.unUsedTwoPlugsList.concat(unUsed);
				this.plug_ids = pids;
			},
			// 保存
			save(formName) {
				if (!this.checkChooseAgentList()) return;
				this.$refs[formName].validate(async valid => {
					if (valid) {
						this.formData.agent_ids = this.cooperateAgentList.map(item => item.agent_id).join(',');
						this.formData.agent_data = JSON.stringify(this.cooperateAgentList);
						this.formData.cooperate_rule = JSON.stringify(this.cooperate_rule);

						if (this.plug_ids.length > 0) {
							this.formData.plug_ids = this.plug_ids.join(',');
						}
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.formData)
						}
						const loading = this.$loading();
						await this.$api.Device.EditDevice(params)
						this.$notify({
							title: '成功',
							message: '编辑成功',
							type: 'success'
						})
						loading.close();
						this.closeTab()
					}
				})
			},
			// 关闭提示
			close() {
				this.$confirm('确定要关闭当前页面吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.closeTab()
				})
			},
			// 关闭tab
			closeTab() {
				let tabs = this.$store.state.app.tabList
				this.setTabList(tabs.filter(tab => tab.name !== 'device-device-edit'))
				this.$router.push({
					name: 'device-device-list'
				})
			},
			// 打开地图
			openMap() {
				this.showMap = true
			},
			// 地图回调
			handleMapComplete(address) {
				this.showMap = false
				// this.formData.province = address.addressComponents.province
				// this.formData.city = address.addressComponents.city
				// this.formData.county = address.addressComponents.district
				// const point = bd2gd(address.point.lng, address.point.lat)
				this.formData.province = address.province;
				this.formData.city = address.city;
				this.formData.county = address.district;
				const point = bd2gd(address.lng, address.lat);
				this.formData.longitude = point.lng;
				this.formData.latitude = point.lat;
				this.formData.city_code = '';
			},
			// 地图关闭
			handleMapClose() {
				this.showMap = false
			},
			onAgentItemAdd: function () {
				this.cooperateAgentList.push({
					agent_id: null,
					ratio: null
				});
			},
			onAgentItemDel: function (idx) {
				this.cooperateAgentList.splice(idx - 1, 1);
			},
			checkChooseAgentList: function () {
				let totalRatio = 0;
				this.cooperateAgentList.forEach(item => {
					if (item.agent_id != null) {
						totalRatio += item.ratio
					}
				})
				if (totalRatio !== 1) {
					this.$notify.error("代理商分成比例累计需要为100%！")
				}
				return totalRatio === 1;
			}
		}
	}
</script>
<style lang="scss" scoped>
	.agent-choose-form {
		padding-left: 40px;

		.title {
			.tips {
				font-size: 14px;
				color: #606266;
			}

			.add-button {
				margin-left: 20px;
			}
		}

		.agent-item {
			display: flex;
			width: 100%;
			padding: 10px 10px;

			.select {
				margin-left: 10px;
				width: 250px;
			}

			.input {
				margin-left: 10px;
				width: 250px;
			}

			.del-button {
				margin-left: 10px;
			}
		}

		.agent-choose-error-tips {
			margin-left: 20px;
			height: 20px;

			span {
				color: #F56C6C;
				font-size: 14px;
			}
		}
	}
</style>
